import React, { FC, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { Accordion, Container, Image, Label, Text } from '@legalshield/adonis-ux-framework';
import { Users } from '@legalshield/frontend-commons/src/utils/users';
import { PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import PaymentDropdown from '../../PaymentDropdown';
import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { SubscriptionBenefitsModal } from './SubscriptionBenefitsModal/SubscriptionBenefitsModal';
import { formatDate, isAssociate, isLegal } from '../../utils/utils';
import { getBillingFrequency } from '../../utils/getBillingFrequency';
import { getFriendlyProductName } from '../../utils/getFriendlyName';
import { populateTemplateString } from '../../../../utils/stringFormat';
import { useGetGroupByGroupId } from '../../../Payments/hooks/useGetGroup';

import './Subscription.scss';

interface SubscriptionProps {
  subscription?: ISubscriptionResource;
  isLoading?: boolean;
}

interface SubscriptionPreviewProps {
  logo: string;
  price: string;
  productName: string;
  realmDomain: string;
  subtitle: string;
}

const SubscriptionPreview: FC<SubscriptionPreviewProps> = ({ logo, price, productName, realmDomain, subtitle }) => (
  <>
    <Container>
      <Image
        id="product-logo"
        classNames={['mr-4']}
        height={32}
        width={32}
        src={'https://design.api.' + realmDomain + '/assets/logos/' + logo + '-square-logo.svg'}
        alt={string_table.PRODUCT_LOGO_ALT}
      />
    </Container>
    <Container flexbox flexDirection="column" alignItems="flex-start">
      <Text textSize="extra-large" id="subscription-name" text={productName} />
      <Container flexbox flexDirection="row" alignItems="center" classNames={['pt-2']}>
        <Text textSize="large" classNames={['accounts-subscription-item__header-price', 'pr-2']}>
          {price}
        </Text>
        <Text textSize="medium" classNames={['accounts-subscription-item__header-bill-date']}>
          {subtitle}
        </Text>
      </Container>
    </Container>
  </>
);

const SubscriptionPreviewSkeleton: FC = () => (
  <>
    <Container>
      <Skeleton width={32} height={32} className="mr-4" />
    </Container>
    <Container flexbox flexDirection="column" alignItems="flex-start">
      <Skeleton count={1} height={28} width={260} />
      <Container flexbox flexDirection="row" alignItems="center" classNames={['pt-2']}>
        <Skeleton count={1} height={22} width={160} className="accounts-subscription-item__header-price mr-2" />
        <Skeleton count={1} height={18} width={160} className="accounts-subscription-item__header-bill-date" />
      </Container>
    </Container>
  </>
);

export const Subscription: FC<SubscriptionProps> = ({ subscription, isLoading }) => {
  const groupId = subscription?.source?.groupId;
  const { data: groupData } = useGetGroupByGroupId(groupId);
  // const { data: invoicesData, isLoading: invoicesLoading } = useGetOutstandingInvoices(subscription?.id);

  const nextBillOn = formatDate(subscription?.nextBillOn);
  const memberSince = formatDate(subscription?.startedOn);

  const isLegalPlan = isLegal(subscription?.productBrochure?.productFamily);
  const isAssociatePlan = isAssociate(subscription?.productBrochure?.productFamily);
  const logo: string = isLegalPlan ? 'legalshield' : isAssociatePlan ? 'pplsi' : 'idshield';
  const billingPeriod = getBillingFrequency(subscription?.billingFrequency.period);
  const price = `$${subscription?.price?.toFixed(2)} ${string_table.PRODUCT_BILL_FREQUENCY} ${billingPeriod}`;

  /* Plan Benefits */
  // const { data: eligibilityBenefits } = useGetEligibilityBenefits({ subscription });
  // const supplementBenefits = eligibilityBenefits?.flatMap((benefits) => benefits.supplementBenefits);

  // const SUBSCRIPTION_AMOUNT_DUE = populateTemplateString(string_table.SUBSCRIPTION_AMOUNT_DUE, {
  //   price: invoicesData?.outstandingInvoices?.totalBillingAmount.toFixed(2),
  // });

  const SUBSCRIPTION_MEMBER_NUMBER = populateTemplateString(string_table.SUBSCRIPTION_MEMBER_NUMBER, {
    memberNumber: subscription?.source?.friendlyId,
  });

  // const SUBSCRIPTION_PAST_DUE = populateTemplateString(string_table.SUBSCRIPTION_PAST_DUE, {
  //   pastDue: invoicesData?.outstandingInvoices?.invoicesBehind.toString(),
  // });

  const shouldRenderPaymentDropdown = () => {
    return true;
    /* Hide payment dropdown if user is not self pay */
    if (groupData?.payType !== PayType.SELF_PAY) return false;

    /* Hide payment dropdown is groupData is available */
    if (groupData) return false;

    /* If the user is impersonated, return true  */
    if (Users.isImpersonated()) return true;
  };

  return (
    <Accordion.Item value={subscription?.id} classNames={['accounts-subscription']}>
      <Accordion.Trigger
        size="small"
        classNames={['my-4']}
        headingChildren={
          <Container flexDirection="row" flexbox alignItems="flex-start" classNames={['m-5']}>
            {isLoading ? (
              <SubscriptionPreviewSkeleton />
            ) : (
              <SubscriptionPreview
                logo={logo}
                price={price}
                productName={getFriendlyProductName(subscription)}
                realmDomain={pplsi.realmDomain}
                subtitle={`${string_table.PRODUCT_NEXT_BILL_DATE} ${nextBillOn}`}
              />
            )}
          </Container>
        }
      />
      <Accordion.Content>
        <div className="accounts-subscription-item__content p-5">
          <div className="accounts-subscription-item__content__section">
            <Label
              text={string_table.SUBSCRIPTIONS_MEMBERSHIP_DETAILS}
              classNames={['accounts-subscription-item__content__section__label']}
            />
            <Text text={SUBSCRIPTION_MEMBER_NUMBER} classNames={['mb-1']} />
            <Text text={`${string_table.PRODUCT_MEMBER_SINCE_DATE} ${memberSince}`} classNames={['mb-1']} />
            <SubscriptionBenefitsModal subscription={subscription} />
          </div>
          <div className="accounts-subscription-item__content__section">
            {subscription && shouldRenderPaymentDropdown() && (
              <Container
                flexbox
                flexDirection="row"
                background="none"
                justifyContent="flex-end"
                classNames={['pt-2']}
                id={`subsciption-${subscription.id}-payment`}
              >
                <div>
                  <Label text="Payment Details" classNames={['accounts-subscription-item__content__section__label']} />
                  <PaymentDropdown key={subscription?.paymentMethodId} subscription={subscription} />
                </div>
              </Container>
            )}
            {/* <Label text="Payment Details" classNames={['accounts-subscription-item__content__section__label']} />
            <Container flexbox flexDirection="row" background="none" justifyContent="flex-start" alignItems="center">
              <Icon name="payments_visa" size="xlarge" />
              <div>
                <Text text="Credit card ••••1234" classNames={['ml-3']} />
              </div>
              <div>
                <Link to="/wallet" className="ml-3">
                  Change
                </Link>
              </div>
            </Container> */}
          </div>
        </div>
      </Accordion.Content>
    </Accordion.Item>
  );
};
