import { Container, Divider, Heading, Icon, Spinner, Text, Toggle, Tooltip } from '@legalshield/adonis-ux-framework';
import { debounce } from 'lodash';
import React, { useState } from 'react';

export interface ToggleControlProps {
  label: string;
  subText?: string;
  tooltipText?: string;
  onToggle: (newValue: boolean) => void;
  toggled?: boolean;
  disabled?: boolean;
  divider?: boolean;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}

export const ToggleControl: React.FC<ToggleControlProps> = ({
  label,
  subText = null,
  tooltipText = null,
  toggled = false,
  onToggle,
  disabled = false,
  divider = false,
  flexDirection = 'row',
  alignItems = 'flex-start',
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleToggleClick = async () => {
    setLoading(true);
    try {
      await onToggle(!toggled);
    } finally {
      setLoading(false);
    }
  };

  const debouncedHandleToggleClick = debounce(handleToggleClick, 100);

  return (
    <>
      <Container
        flexbox
        flexDirection={flexDirection}
        alignItems={alignItems}
        justifyContent="space-between"
        classNames={['my-4']}
      >
        {subText == null ? (
          <Container flexbox alignItems="center">
            <Container classNames={['ml-6', 'mr-2']}>
              <Heading as="T14" text={label} />
            </Container>
            {tooltipText && (
              <Tooltip theme="light-border" placement="bottom" text={tooltipText}>
                <Icon size="small" color="N500" name="alert_help" />
              </Tooltip>
            )}
          </Container>
        ) : (
          <Container flexbox flexDirection="column" classNames={['mr-5']} style={{ maxWidth: '70%' }}>
            <Container classNames={['mb-3']}>
              <Heading as="T14" text={label} />
            </Container>
            <Container>
              <Text text={subText} style={{ color: '#737373' }}></Text>
            </Container>
          </Container>
        )}
        {loading ? (
          <Spinner />
        ) : (
          <Toggle
            onClick={debouncedHandleToggleClick}
            disabled={disabled}
            isChecked={toggled}
            classNames={['mr-4']}
            switchSize={'large'}
          />
        )}
      </Container>
      {divider && (
        <Container alignItems="center">
          <Divider />
        </Container>
      )}
    </>
  );
};
