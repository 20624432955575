export enum LsEnv {
  Dev = 'dev',
  Prod = 'prod',
  Uat = 'uat',
  Local = 'local',
}
interface EnvMap {
  apps: {
    access: string;
    accounts: string;
    activate: string;
    associate: string;
    forms: string;
    ids: string;
    launch: string;
    legal: string;
    login: string;
    paymentsFrame: string;
  };
  domain: string;
  externalSites: {
    googleAuthPlay: string;
    idsPlayStore: string;
    lsAppStore: string;
    lsPlayStore: string;
  };
  gtm: {
    auth: string;
    gtmId: string;
    preview: string;
  };
  images: {
    appStoreImage: string;
    playStoreImage: string;
  };
  timeout: number;
}

const getLsEnv = (): LsEnv => {
  const url = new URL(window.location.href);
  const port = url.port;

  if (realmDomain.includes('uat-')) {
    return LsEnv.Uat;
  } else if (realmDomain.includes('dev-')) {
    if (Number(port) >= 3000 && Number(port) <= 3999) {
      return LsEnv.Local;
    } else {
      return LsEnv.Dev;
    }
  } else {
    return LsEnv.Prod;
  }
};

const defaultEnv = {
  apps: {
    access: 'access',
    accounts: 'accounts',
    activate: 'activate',
    associate: 'associate-office',
    forms: 'forms',
    ids: 'ids',
    launch: 'mybusiness',
    legal: 'legal',
    login: 'login',
    paymentsFrame: 'paymentsv2',
  },
  domain: realmDomain,
  externalSites: {
    googleAuthPlay: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    idsAppStore: 'https://apps.apple.com/us/app/%CE%B9dshield/id1444809858',
    idsPlayStore: 'https://play.google.com/store/apps/details?id=com.legalshield.idshield',
    lsAppStore: 'https://apps.apple.com/us/app/legalshield-legal-protection-you-can-trust/id924247236',
    lsPlayStore: 'https://play.google.com/store/apps/details?id=com.legalshield.MembersOnly',
  },
  gtm: {
    auth: 'hgqMjKU_-2aD9HYq5Jf5bA',
    gtmId: 'GTM-K8W5L8M',
    preview: 'env-1',
  },
  images: {
    appStoreImage: 'https://www.legalshield.com/themes/custom/paragon_themekit/dist/images/svg/app-store.svg',
    playStoreImage: 'https://www.legalshield.com/themes/custom/paragon_themekit/dist/images/svg/google-play.svg',
  },
  timeout: 5000,
} as EnvMap;
const currentEnv = Object.assign({}, defaultEnv);
const lsEnv = getLsEnv();
switch (lsEnv) {
  case 'dev':
    currentEnv.gtm = {
      auth: 'lB55iguQCsPBRo_tKKYAgQ',
      gtmId: 'GTM-K8W5L8M',
      preview: 'env-37',
    };
    break;
  case 'uat':
    currentEnv.gtm = {
      auth: 'PZODdoi5oAm0cbWrTSvkWA',
      gtmId: 'GTM-K8W5L8M',
      preview: 'env-38',
    };
    break;
}

export default {
  currentEnv: currentEnv,
  getLsEnv: getLsEnv,
};
