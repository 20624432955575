import React, { FC, Fragment, useLayoutEffect, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Container, Footer, Layout } from '@legalshield/adonis-ux-framework';
import { Login } from '@legalshield/frontend-commons';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { clarity } from 'react-microsoft-clarity';

import FAQ from '../FAQ/FAQ';
import Multifactor from '../Multifactor/Multifactor';
import NewProfile from '../NewProfile/NewProfile';
import Notifications from '../Notifications/Notifications';
import Overview from '../Overview/Overview';
import PageNotFound from '../PageNotFound/PageNotFound';
import Payments from '../Payments/Payments';
import ProductDetails from '../Payments/ProductDetails/ProductDetails';
import Resources from '../Resources/Resources';
import Security from '../Security/Security';
import Setup from '../Setup/Setup';
import Unsubscribe from '../Unsubscribe/Unsubscribe';
import UnsubscribeByEmail from '../Unsubscribe/UnsubscribeByEmail';
import Wallet from '../Wallet/Wallet';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import { Password } from '../Security/Password/Password';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';

/** Map the deprecated routes to their new URL paths */
const deprecatedRoutes: Record<string, string> = {
  '/home': '/overview',
  '/mfa': '/security/mfa',
  '/overview/notifications': '/notifications',
  '/overview/profile': '/profile',
  '/overview/subscriptions': '/payments',
  '/preferences': '/notifications',
  '/subscriptions': '/payments',
};

/** Scroll to top when route changes */
const ScrollWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App: FC = () => {
  ads_set_title(string_table.TITLE, true);

  const { enableClarity, newSubscriptionsUi } = useLaunchDarkly();

  useEffect(() => {
    if (enableClarity) {
      clarity.init('nc0vrklyhw');
    }
  }, [enableClarity]);
  if (!location.pathname) {
    return <Fragment />;
  }

  if (!pplsi?.authNPayload?.sub) {
    return (
      <Routes>
        <Route path="/">{(window.location.href = Login.getLoginUrl())}</Route>
        <Route path="/p/identities/:identityId/profile/unsubscribeFromCommunication" element={<Unsubscribe />} />
        <Route path="/p/profile/:emailAddress/unsubscribeFromCommunication" element={<UnsubscribeByEmail />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<LayoutWithNav />}>
        {/* Unsubscribe Routes */}
        <Route path="/p/identities/:identityId/profile/unsubscribeFromCommunication" element={<Unsubscribe />} />
        <Route path="/p/profile/:emailAddress/unsubscribeFromCommunication" element={<UnsubscribeByEmail />} />
        {/* Active Routes */}
        <Route path="overview" element={<Overview />} />
        <Route path="profile" element={<NewProfile />} />
        <Route path="payments/details/:id" element={<ProductDetails />} />
        <Route path="subscriptions/details/:id" element={<ProductDetails />} />
        <Route
          path="/payments"
          element={
            newSubscriptionsUi ? (
              <Navigate to="/payments/subscriptions" replace />
            ) : (
              <Payments newSubscriptionsUi={newSubscriptionsUi} />
            )
          }
        />

        {newSubscriptionsUi ? (
          <Route path="/payments/*" element={<Payments newSubscriptionsUi={newSubscriptionsUi} />} />
        ) : (
          <Route path="payments" element={<Payments newSubscriptionsUi={newSubscriptionsUi} />} />
        )}
        <Route path="wallet" element={<Wallet />} />
        <Route path="security" element={<Security />} />
        <Route path="security/password" element={<Password />} />
        <Route path="security/mfa" element={<Multifactor />} />
        <Route path="resources" element={<Resources />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="setup/*" element={<Setup />} />
        {/* Deprecated Routes */}
        {Object.entries(deprecatedRoutes).map(([from, to]) => (
          <Route key={from} path={from} element={<Navigate to={to} />} />
        ))}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const LayoutWithNav: FC = () => (
  <ScrollWrapper>
    <Layout variant="scroll" navigation={<NavigationBar />}>
      <Container>
        <Outlet />
      </Container>
      <ReactQueryDevtools initialIsOpen={false} />
      <Footer
        classNames={['lsux-layout-padding']}
        align={window.location.pathname.includes('/setup') ? 'center' : 'right'}
        htmlContent={footerHtml}
      />
    </Layout>
  </ScrollWrapper>
);

export default withLDProvider({
  clientSideID: LDClientID,
  context: {
    kind: 'multi',
    organization: {
      key: 'PPLSI',
      name: 'PPLSI',
    },
    user: {
      email: pplsi.authNPayload?.ual ?? '',
      key: pplsi.authNPayload?.sub ?? '',
      language: pplsi.market.split('-')[0] ?? '',
      name: pplsi.authNPayload?.sub_name ?? '',
    },
  },
  options: {
    bootstrap: 'localStorage',
  },
})(App);
