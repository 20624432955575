import React, { FC } from 'react';
import { Modal } from '@legalshield/adonis-ux-framework';
import { Link } from 'react-router-dom';

import { ISubscriptionResource } from '../../../../Payments/hooks/utils/subscription-utils';

import './SubscriptionBenefitsModal.scss';
import { SupplementInfo } from '../../../../Payments/ProductDetails/SupplementInfo';
import { useGetEligibilityBenefits } from '../../../hooks/useEligibiltyBenefits';

interface SubscriptionBenefitsModalProps {
  subscription?: ISubscriptionResource;
}

export const SubscriptionBenefitsModal: FC<SubscriptionBenefitsModalProps> = ({ subscription }) => {
  const { data: eligibilityBenefits } = useGetEligibilityBenefits({ subscription });

  const supplementBenefits = eligibilityBenefits?.flatMap((benefits) => benefits.supplementBenefits);

  return supplementBenefits && supplementBenefits?.length > 0 ? (
    <Modal
      classNames={['sub-benefits-modal']}
      trigger={
        <Modal.Trigger asChild>
          <Link to="#" className="sub-benefits-modal__link lsux-text--medium">
            {string_table.PAYMENTS_SUBSCRIPTIONS_VIEW_BENEFITS}
          </Link>
        </Modal.Trigger>
      }
      maxHeight="80vh"
      maxWidth="800px"
      position="center"
    >
      <Modal.Title>Benefits</Modal.Title>
      <SupplementInfo subscription={subscription} />
    </Modal>
  ) : (
    <React.Fragment />
  );
};
