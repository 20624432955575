import React, { FC } from 'react';
import { Accordion, Container, Text } from '@legalshield/adonis-ux-framework';

import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { Subscription } from './Subscription/Subscription';

import './Subscriptions.scss';

interface SubscriptionsProps {
  subscriptions: ISubscriptionResource[];
  isLoading: boolean;
}

const Subscriptions: FC<SubscriptionsProps> = ({ subscriptions = [], isLoading }) => (
  <Container classNames={['accounts-subscriptions']} flexbox flexDirection="column">
    <Accordion.Root type="single" defaultValue={[subscriptions?.map[0]?.id ?? '']}>
      {isLoading ? (
        <>
          <Subscription isLoading={true} />
          <Subscription isLoading={true} />
        </>
      ) : subscriptions.length ? (
        subscriptions.map((sub) => <Subscription key={sub?.id} subscription={sub} />)
      ) : (
        <Text text="No subscriptions found" classNames={['my-4']} />
      )}
    </Accordion.Root>
  </Container>
);

export default Subscriptions;
